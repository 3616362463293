import React from 'react';
import {useHistory} from 'react-router-dom';
import {COLORS, ROUTES} from '../../constants';
import LOGO from '../../assets/logowhite.png';
import img01 from '../../assets/images/new/IMG_9701.jpeg';
import {BookButton} from '../../styled';

import {
  TreatmentContainer,
  TreatmentTitle,
  TreatmentDescription,
  LogoBookButtonWrapper,
} from '../../styled';

const PigmentVessels = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{width: '100%'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: '100px', position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
          <img
            src={img01} alt="Image of table with Sax and the City text"
            style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
          />
        </div>
        <LogoBookButtonWrapper>
          <img src={LOGO} style={{width: '50%'}} />
          <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
        </LogoBookButtonWrapper>
      </div>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Cellulitbehandling Malmö</TreatmentTitle>
          <TreatmentDescription>
            <h3>Upptäck kompressiv mikro-vibrationsbehandling – En revolutionerande metod mot celluliter och förbättrad estetik</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            <b>EFFEKTIV MOT CELLULITER OCH STÖDJER LYMFSYSTEMET</b>
          </TreatmentDescription>
          <TreatmentDescription>
            Denna teknik arbetar effektivt mot celluliter genom att stimulera blodcirkulationen och lymfsystemet, vilket hjälper till att reducera vätskeretention och inflammation. Behandlingen tonar även huden, vilket bidrar till ett mer fast och slätt utseende. Dessutom är behandlingen idealisk för rumplyft och kroppskonturering.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>INVESTERA I DENNA TEKNOLOGI OCH TA DIN KLINIK TILL NÄSTA NIVÅ</b>
          </TreatmentDescription>
          <TreatmentDescription>
            Genom att välja denna metod får din klinik tillgång till en banbrytande teknologi som kombinerar vetenskap och innovation för att leverera märkbara och långvariga resultat. Dessutom kommer det kontinuerliga stödet från våra partner att säkerställa att du alltid har den senaste teknologin och bästa möjliga service. Investera i denna teknologi och ge dina kunder den bästa möjliga estetiska behandlingen – en investering som garanterat kommer att löna sig.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
      <TreatmentContainer bg={COLORS.brownRGBA2}>
        <div>
          <TreatmentTitle>Fördelar med Kompressiv Mikro-vibrationsbehandling – Din kompletta lösning för estetisk förbättring</TreatmentTitle>
          <TreatmentDescription>
            Denna teknik erbjuder en rad fördelar som gör den till ett oumbärligt verktyg för skönhetskliniker som vill leverera exceptionella resultat och upplevelser för sina kunder.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>FÖRDELAR MED BEHANDLINGEN:</b>
          </TreatmentDescription>
          <TreatmentDescription>
            <ul>
              <li><b>Minskar Celluliter</b>: Effektiv metod som bryter ner celluliter och förbättrar hudens textur, vilket ger en slätare och jämnare hud.</li>
              <li><b>Stödjer Lymfsystemet</b>: Stimulering av lymfcirkulationen hjälper till att eliminera toxiner och minska svullnad, vilket främjar en friskare kropp.</li>
              <li><b>Rumplyft</b>: Ökar fastheten och lyfter rumpan för en mer ungdomlig och definierad kontur.</li>
              <li><b>Hudtoning</b>: Förbättrar hudens fasthet och elasticitet, vilket ger ett slätt och tonat utseende som dina kunder kommer att älska.</li>
              <li><b>Noninvasiv Behandling</b>: Säker och smärtfri metod utan behov av återhämtningstid, vilket gör det möjligt för kunder att återgå till sina dagliga aktiviteter omedelbart.</li>
            </ul>
          </TreatmentDescription>
          <TreatmentDescription>
            Med denna teknik kan din klinik erbjuda en avancerad, beprövad lösning som inte bara förbättrar kroppens utseende utan också främjar långsiktig hälsa och välmående. Investera i denna behandling och ge dina kunder den bästa möjliga estetiska upplevelsen.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Synliga resultat med kompressiv mikro-vibrationsbehandling – För kropp och ansikte</TreatmentTitle>
          <TreatmentDescription>
            Denna erkända och icke-invasiva behandling skulpterar snabbt kroppen och ger synliga resultat. Behandlingen eliminerar fettdepåer och vätskeansamlingar som kan uppstå när lymfsystemet och cirkulationen inte fungerar optimalt.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>RESULTAT AV BEHANDLINGEN:</b>
          </TreatmentDescription>
          <TreatmentDescription>
            <ul>
              <li><b>Reducerar Celluliter</b>: Förbättrar lymfcirkulationen och dränerar överflödig vätska, vilket effektivt minskar celluliter.</li>
              <li><b>Eliminerar Fettansamlingar</b>: Stimulerar blodflödet för att bryta ner fettdepåer och främja en jämnare kroppsform.</li>
              <li><b>Minskar Inflammation och Smärta</b>: Aktiverar cirkulationen för att minska inflammation och lindra smärta och värk.</li>
              <li><b>Ökar Muskeltonus</b>: Genom djupgående massage som stärker och tonar musklerna.</li>
              <li><b>Förbättrar Hudens Spänst och Elasticitet</b>: Mikro-stimuleringstekniken ökar hudens fasthet och elasticitet, vilket ger en mer ungdomlig och spänstig hud.</li>
            </ul>
          </TreatmentDescription>
          <TreatmentDescription>
            Upplev de fantastiska fördelarna med denna behandling och få en skulpterad, smärtfri och strålande kropp och ansikte.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
      <TreatmentContainer bg={COLORS.brownRGBA2}>
        <div>
          <TreatmentTitle>Fördelar med kompressiv mikro-vibrationsbehandling – En revolutionerande metod för estetisk förbättring</TreatmentTitle>
          <TreatmentDescription>
            <ul>
              <li><b>Effektiv Användning över hela kroppen</b>: Effektiv för att eliminera celluliter och strama upp lös hud.</li>
              <li><b>Icke-kirurgisk och smärtfri</b>: En säker och behaglig behandling utan ingrepp.</li>
              <li><b>Inga farliga kemikalier</b>: Naturlig och skonsam metod.</li>
              <li><b>Ökar cirkulationen</b>: Förbättrar blodflödet och främjar hälsa.</li>
              <li><b>Lindrar muskelinflammation</b>: Minskar inflammation och smärta i muskler.</li>
              <li><b>Stimulerar kollagen och hudens elasticitet</b>: Förbättrar hudens fasthet och spänst.</li>
              <li><b>Synliga resultat efter första sessionen</b>: Snabba och märkbara effekter.</li>
              <li><b>Testad i Europa och USA</b>: Beprövad och pålitlig teknik.</li>
              <li><b>CE-märkt</b>: Uppfyller höga säkerhets- och kvalitetsstandarder.</li>
            </ul>
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
    </div>
  );
};

export default PigmentVessels;
