import styled from 'styled-components';

export const Title = styled.section`
  text-shadow: 5px 5px #333;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  color: #FFF;
  position: absolute;
  top: ${({top}) => top || '5%'};
  width: 100%;
  height: 100px;
  font-size: 5em;
  font-weight: 800;
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: none;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    display: none;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    display: none;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    display: none;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    display: none;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    display: none;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
`;
