import React from 'react';
import {withRouter} from 'react-router-dom';
import Bar from '../bar';
import Footer from '../footer';
import {StyledRoutes} from './styled';

const Routes = ({children, location}) => {
  const showBar = location.pathname !== '/entry';
  const renderBar = () => showBar && <Bar/>;

  const renderRoutes = () => (
    <>
      {renderBar()}
      <StyledRoutes showBar={showBar}>
        {children}
      </StyledRoutes>
      <Footer />
    </>
  )

  return renderRoutes();
};

export default withRouter(Routes);
