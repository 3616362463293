import React from 'react';
import {useHistory} from 'react-router-dom';
import Collapsible from 'react-collapsible';
import {COLORS} from '../../constants';
import LOGO from '../../assets/logowhite.png';
import {BookButton} from '../../styled';
import {ROUTES} from '../../constants';
import img01 from '../../assets/images/new/IMG_9018.jpeg';
import styled from 'styled-components';

import {
  TreatmentContainer,
  TreatmentTitle,
  TreatmentDescription,
  CollapsibleLabel,
  LogoBookButtonWrapper,
} from '../../styled';

const OpenLabel = styled.div`
  right: 10px;
  position: absolute;
  font-size: 1.5em;
`;

const Label = ({children, isOpen}) => {
  return (
    <CollapsibleLabel>
      {children}
      {isOpen 
        ? <OpenLabel>-</OpenLabel>
        : <OpenLabel>+</OpenLabel>
      }
    </CollapsibleLabel>
  );
};

const SkinTightening = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [open, setOpen] = React.useState({});

  return (
    <div style={{width: '100%'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: '100px', position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
          <img
            src={img01} alt="Image of table with Sax and the City text"
            style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
          />
        </div>
        <LogoBookButtonWrapper>
          <img src={LOGO} style={{width: '50%'}} />
          <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
        </LogoBookButtonWrapper>
      </div>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Hudåtstramning Malmö med laser</TreatmentTitle>
            <TreatmentDescription>
            Våra toppmoderna laserenheter är konstruerade för att rikta sig djupt under hudens yta, vilket kickstartar den naturliga produktionen av kollagen och elastin. Dessa essentiella proteiner är grunden för hudtillväxt och reparation, vilket säkerställer dess jämna kontur och motståndskraft.
          </TreatmentDescription>
          <TreatmentDescription>
            En typisk huduppstramningsbehandling omfattar vanligtvis tre sessioner med en månads mellanrum för att uppnå optimala resultat. Lita på vår kliniskt beprövade teknik för att föryngra din klients hud.
          </TreatmentDescription>
          <TreatmentDescription>
            Med våra innovativa enheter kan du tryggt erbjuda patienterna en icke-invasiv resa för att återställa hudens vitalitet, strama upp slapp hud och vrida tillbaka klockan när det gäller åldrande.
          </TreatmentDescription>
          <TreatmentDescription>
            Utforska möjligheterna med laserstramning av huden och lyft din praktik till att bli en global ledare inom icke-invasiva kosmetiska ingrepp, med stöd av läkares rekommendationer och kliniska studier.
          </TreatmentDescription>
          <TreatmentTitle>Fördelarna med laser</TreatmentTitle>
          <TreatmentDescription>
            Hudstramningsanordningar med laser låser upp en icke-invasiv väg för att föryngra trött, åldrande hud. Med hjälp av våra högpresterande enheter kan utövare sömlöst leverera anmärkningsvärt effektiva behandlingar som lämnar kunderna med en friskare hud med ett ungdomligt utseende.
          </TreatmentDescription>
          <TreatmentDescription>
            Driftstoppen i samband med huduppstramningsbehandlingar är minimal till obefintlig, vilket säkerställer att kunderna snabbt kan återuppta sina dagliga rutiner.
          </TreatmentDescription>
        </div>
          <div style={{display: 'flex', flexDirection: 'column'}}>
            <h3>Vanliga frågor</h3>
            <Collapsible onClose={() => setOpen({one: false})} onOpen={() => setOpen({one: true})} trigger={<Label isOpen={open['one']}>Är det smärtsamt?</Label>}>
              <TreatmentDescription>
                Behandlingar för hudåtstramning är inte smärtsamma och tolereras väl av patienter. Denna icke-invasiva metod för att reparera hudvävnad säkerställer maximal komfort för patienten samtidigt som fantastiska resultat uppnås.
              </TreatmentDescription>
            </Collapsible>
            <Collapsible onClose={() => setOpen({two: false})} onOpen={() => setOpen({two: true})} trigger={<Label isOpen={open['two']}>Hur förnyas huden?</Label>}>
              <TreatmentDescription>
                Under behandling för hudåtstramning levererar laserenergin värme till hudvävnaden. Denna värme förstör fibroblasterna och stimulerar bildandet av nytt kollagen. Kollagenfibrer är ansvariga för att få huden att se yngre och fastare ut.
              </TreatmentDescription>
            </Collapsible>
            <Collapsible onClose={() => setOpen({three: false})} onOpen={() => setOpen({three: true})} trigger={<Label isOpen={open['three']}>Hur ser patienten ut efter behandling?</Label>}>
              <TreatmentDescription>
                Efter behandlingen kan patientens hud vara något rödare i det behandlade området. Denna rodnad försvinner inom 30 minuter. I vissa sällsynta fall kan hudåtstramningsbehandlingen leda till hematom, petekier eller mindre hudirritationer.
              </TreatmentDescription>
              <TreatmentDescription>
                Hårväxt sker i 4 faser: tidig anagen (tidig hårväxt), anagen (aktiv hårväxt), catagen (regression) och telogen (vilar, växer inte). Hårborttagning med laser tar endast bort aktivt växande hår, varför flera behandlingar är nödvändiga för att säkerställa fullständig effekt.
              </TreatmentDescription>
            </Collapsible>
            <Collapsible onClose={() => setOpen({four: false})} onOpen={() => setOpen({four: true})} trigger={<Label isOpen={open['four']}>Hur många behandlingar krävs för fastare hud?</Label>}>
              <TreatmentDescription>
                Minst tre hudåtstramningsbehandlingar bör genomföras för att uppnå maximala resultat. Efter den första behandlingsomgången rekommenderas det också att uppdatera behandlingen årligen.
              </TreatmentDescription>
            </Collapsible>
          </div>
      </TreatmentContainer>
    </div>
  );
};

export default SkinTightening;
