import React from 'react';
import {useHistory} from 'react-router-dom';
import {COLORS, ROUTES} from '../../constants';
import LOGO from '../../assets/logowhite.png';
import {BookButton} from '../../styled';
import img01 from '../../assets/images/new/IMG_8818.jpeg';

import {
  TreatmentContainer,
  TreatmentTitle,
  TreatmentDescription,
  LogoBookButtonWrapper,
} from '../../styled';

const InsculptChair = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: '100px', position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
          <img
            src={img01} alt="Image of table with Sax and the City text"
            style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
          />
        </div>
        <LogoBookButtonWrapper>
          <img src={LOGO} style={{width: '50%'}} />
          <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
        </LogoBookButtonWrapper>
      </div>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Bäckenbottenträning Malmö</TreatmentTitle>
          <TreatmentDescription>
            Är en unik metod som stärker din bäckenbottenmuskulatur. Både män och kvinnor kan stärka sin muskulatur för att lindra besvär med inkontinens, bäckenbottenproblem efter förlossning och/eller nedsatt intim tillfredsställelse. 
            Över 60% av alla kvinnor lider någon gång av urinläckage. Det kan vara när man tränar, nyser eller anstränger sig.
          </TreatmentDescription>
          <TreatmentDescription>
            Insculpt chair använder EMS, elektromagnetisk stimulering för att stimulera och stärka muskulaturen i bäckenbotten, inte bara de som tränas av knipövningar.  
            Dessa starkare bäckenbottenmuskler gör att du kan njuta av dina dagliga aktiviteter utan rädsla för bla urinläckage.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Vi rekommenderar att du först ta en kur med minst 8 behandlingar, 2 gånger i veckan (med vilodag).</b>
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Vid svåra problem och behandling för män kan kräva 2 kurer.</b>
          </TreatmentDescription>
          <TreatmentDescription>
            Därefter kan du underhålla resultat med minst en gång/månad.
          </TreatmentDescription>
          <TreatmentDescription>
            Bäckenbottenmusklerna är det lager av muskler som stöder bäckenorganen och sträcker sig över bäckenbotten. Starka bäckenbottenmuskler ger kontroll över blåsan och tarmen. 
          </TreatmentDescription>
          <TreatmentDescription>
            <ul>
              <li>Icke-kirurgisk & smärtfri</li>
              <li>Inga farliga kemikalier</li>
              <li>Ökar cirkulationen</li>
              <li>Stärker muskulaturen</li>
              <li>Icke terapeutledd behandlingbehandlingar</li>
            </ul>
          </TreatmentDescription>
          <TreatmentDescription>
            Insculpt chair använder elektromagnetisk energi för att leverera tusentals supramaximala sammandragningar i bäckenbottenmusklerna under en enda behandling.
          </TreatmentDescription>
          <TreatmentDescription>
            12 000 knip under 28 minuter
          </TreatmentDescription>
          <TreatmentDescription>
            Under en behandling sitter du FULLT PÅKLÄDD i EMS-stolen och kan t.ex. läsa en bok.
            Behandlingen är icke-invasiv behandling, med andra ord finns inga risker för brännskador, ärrbildning eller svullnad.
          </TreatmentDescription>
          <TreatmentDescription>
            INSCULPT använder elektromagnetiska impulser som tränger igenom huden och fettlagren och träffar muskelfibrerna (muskelcellerna). Det skapar muskelsammandragningar som är mer effektiva och djupa än de viljestyrda som vi framkallar vid träning.
          </TreatmentDescription>
          <TreatmentDescription>
            Tekniken är baserad på omfattande forskning och vetenskapliga metoder.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>FÖRDELAR INSCULPT</b>
          </TreatmentDescription>
          <TreatmentDescription>
            INSCULPT CHAIR använder elektromagnetisk energi för att leverera tusentals supramaximala sammandragningar i bäckenbottenmusklerna under en enda behandling.
          </TreatmentDescription>
          <TreatmentDescription>
            Under en behandling sitter du FULLT PÅKLÄDD i EMS-stolen och kan t.ex. läsa en bok.
          </TreatmentDescription>
          <TreatmentDescription>
            BEHANDLINGEN är icke-invasiv behandling, med andra ord finns inga risker för brännskador, ärrbildning eller svullnad.
          </TreatmentDescription>
          <TreatmentDescription>
      <b>KAN MAN TRÄNA BORT INKONTINENS?</b>
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Hemmaträning</b>
          </TreatmentDescription>
          <TreatmentDescription>
            Bäckenbottenträning kan hjälpa dig som har besvär med ansträngningsinkontinens eller med trängningsinkontinens. Träningen stärker musklerna som finns runt ändtarmen, slidan och urinröret. Det hjälper dig att hålla tätt när du anstränger dig. För att träningen ska få bra effekt är det viktigt att du tränar rätt muskler.
          </TreatmentDescription>
            <TreatmentDescription>
              <b>Behandling</b>
            </TreatmentDescription>
            <TreatmentDescription>
              Skillnaden är att INSCULPT CHAIR använder elektromagnetisk energi för att leverera tusentals supramaximala sammandragningar i bäckenbottenmusklerna under en enda behandling.
            </TreatmentDescription>
              <TreatmentDescription>
                Behandlingen är väldigt effektiv mer intensiv än knippövningar. Behandlingen kan däremot hjälpa dig att få kontakt med muskulaturen och sedan fortsätta med knipövningar hemma.
              </TreatmentDescription>
                <TreatmentDescription>
                  <b>VILKA SKALL INTE GÖRA BEHANDLINGEN?</b>
                </TreatmentDescription>
                <TreatmentDescription>
                  <ul>
                    <li>Gravid eller om du ammar</li>
                    <li>Livmoderframfall, utbuktning av slidans främre och bakre väggar</li>
                    <li>Precis genomförd bäckenbotten eller bäckenoperation</li>
                    <li>Piercing eller annan metal i underlivet</li>
                  </ul>
                </TreatmentDescription>
                <TreatmentDescription>
                  <b>HUR LÅNG ÄR BEHANDLINGEN? HUR MÅNGA SESSIONER BEHÖVS?</b>
                </TreatmentDescription>
                <TreatmentDescription>
                  Effektiv behandlingstid är 28 minuter/tillfälle men räkna med att ditt besök tar 40-45 minuter.
                </TreatmentDescription>
                  <TreatmentDescription>
                    Efter 8-10 behandlingar märker de flesta ett bra resultat.
                  </TreatmentDescription>
                    <TreatmentDescription>
                      <b>Vad är inkontinens?</b>
                    </TreatmentDescription>
                    <TreatmentDescription>
                      Det finns flera olika typer av inkontinens.
                    </TreatmentDescription>
                      <TreatmentDescription>
                        <b>Ansträngningsinkontinens</b>
                      </TreatmentDescription>
                      <TreatmentDescription>
                        Detta är den vanligaste formen av inkontinens.

                        Patienter med denna typ av problematik upplever läckage vid ansträngning så som hosta, nysning, skratt, hopp, träning eller andra fysiska aktiviteter.
                        Ansträngningsinkontinensen grundar sig ofta i ålder, graviditet, förlossning, östrogenbrist och övervikt men kan även bero på andra faktorer.
                      </TreatmentDescription>
                        <TreatmentDescription>
                          <b>Trängningsinkontinens/överaktiv blåsa</b>
                        </TreatmentDescription>
                        <TreatmentDescription>
                          Vid trängningsinkontinens drar urinblåsans muskel ihop sig under fyllnadsfasen, trots väldigt liten urinvolym.
                          Det tros bero på en störning i nervsignalen från hjärna och ryggmärg som initierar blåstömning
                        </TreatmentDescription>
                          <TreatmentDescription>
                            <b>Blandinkontinens</b>
                          </TreatmentDescription>
                          <TreatmentDescription>
                            Blandinkontinens är en kombination av de båda olika typerna ovan. Vissa kan uppleva mer av den ena varianten och mindre av den andra.
                            Det tros bero på en störning i nervsignalen från hjärna och ryggmärg som initierar blåstömning
                          </TreatmentDescription>
          </div>
      </TreatmentContainer>
    </div>
  );
};

export default InsculptChair;
