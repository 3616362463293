export const ROUTES = {
  entry: '/entry',
  start: '/start',
  treatments: '/behandlingar-malmo',
  bookings: '/bokningar-malmo',
  contact: '/kontakt-malmo',
  products: '/products',
  insculptChair: '/backenbottentraning-malmo',
  hairRemoval: '/harborttagning-malmo',
  acne: '/acnebehandling-malmo',
  stretchMarks: '/bristningar-malmo',
  skinTightening: '/hudatstramning-malmo',
  pigment: '/pigment-malmo',
  vessels: '/karl-malmo',
  cellulites: '/celluliter-malmo',
};
