import styled from 'styled-components';
import bg from './assets/56.jpg';
import {COLORS} from './constants';

export const Wrapper = styled.main`
  display: flex;
  flex-direction: column;
  font-family: 'Lato', 'Arial', sans-serif;
  color: #FFF;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  background: ${COLORS.white};

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    width: 100%;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    width: 100%;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 100%;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    width: 100%;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 100%;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    width: 100%;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    width: 100%;
  }
  `;

export const BookButton = styled.button`
  background: ${({bg}) => bg || COLORS.brown};
  color: ${COLORS.white};
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;
  font-size: 1.2em;
  margin: 10px;
  width: 160px;
  height: 50px;
  transition: 0.3s;
  &:hover {
    background: ${COLORS.brown2};
    color: ${COLORS.gold};
    transition: all 0.3s;
  }
`;

export const TreatmentContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: ${({p}) => p || '100px 25% 100px'};
  color: ${COLORS.white};
  background: ${({bg}) => bg ||COLORS.brown};

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 20px;
    flex-direction: column;

    .Collapsible__trigger {
      font-size: 12px;
    }

    div {
      width: 95%;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    padding: 20px;
    flex-direction: column;
    .Collapsible__trigger {
      font-size: 12px;
    }
    div {
      width: 95%;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    padding: 20px;
    flex-direction: column;
    .Collapsible__trigger {
      font-size: 12px;
    }
    div {
      width: 95%;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding: 20px;
    flex-direction: column;
    div {
      width: 98%;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    padding: 20px;
    flex-direction: column;
    div {
      width: 98%;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    padding: 20px;
    flex-direction: column;
    div {
      width: 98%;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

export const TreatmentTitle = styled.h2`
  font-size: 2em;
  margin: 10px;
`;

export const TreatmentDescription = styled.p`
  font-size: 1.2em;
  margin: 10px;
`;


export const LogoWrapper = styled.div`
  position: absolute;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: space-around;
  top: 350px;
  height: 300px;
  // media queries
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    top: 100px;
    height: 170px;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    top: 100px;
    height: 170px;

  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    top: 100px;
    height: 170px;

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    top: 100px;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

    top: 100px;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {

    top: 100px;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {

  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
`;

export const CollapsibleLabel = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  font-size: 1.2em;
  padding: 0 10px 0;
  border: 1px solid ${COLORS.gold};
  width: 100%;
  height: 50px;
  cursor: pointer;
  margin: 5px 0;

  // media queries
//  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 0 25px 0;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    padding: 0 25px 0;
  }
 
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    padding: 0 25px 0;

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding: 0 25px 0;

  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    padding: 0 25px 0;

  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    padding: 0 25px 0;

  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {

  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
  
`;

export const LogoBookButtonWrapper = styled.div`
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  top: 200px;
  height: 300px;

  // media queries
//  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    top: 100px;
    height: 170px;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    top: 100px;
    height: 170px;
  }

  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    top: 100px;
    height: 170px;
  }

  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    top: 100px;
  }

  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    top: 100px;
  }

  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    top: 100px;
  }
`;
