import styled from 'styled-components';

export const StyledRoutes = styled.div`
  position: relative;
  display: flex;
  width: 100%;
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    width: 100%;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    width: 100%;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 100%;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    width: 100%;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 100%;
  }
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    width: 100%;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    width: 100%;
    }
`;
