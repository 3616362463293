import React from 'react';
import {Helmet} from 'react-helmet';
import Views from './views';

const App = () => (
  <>
    <Helmet>
      <title>Sax and the City</title>
      <meta name="description" content="Sax and the City - Frisör- och skönhetssalong" />
      <meta name="keywords" content="frisör, skönhet, dockan, malmö, klippning, rakning, stylist, inkontinens, urininkontinens, tarm inkontinens, träning inkontinens, ansträningsinkontinens, hoppa studsa skratta nysa, sexuell dysfunktion, bäckenbottenträning, bäckenbotten problem, längd ryggproblem, längd rygg, vaginal tightning, vaginal uppstramning, nedsatt intim tillfredsställelse, intim tillfredsställelse, orgasm, muskelträning, 12 000 knipövningar 28 minuter, pelvic, laserhårborttagning, diod laser, alexandritt laser, IPL laser, permanent hårborttagning, permanent laserhårborttagning, huduppstramning, hudföryngring, ytliga kärl, kärl behandlingar, borttagning av kärl, pigment, borttagning av pigment, hudton, borttagning av bristningar, stretch marks" />
      <meta property="og:title" content="My Page Title" />
      <meta property="og:description" content="Sax and the City - Frisör- och skönhetssalong" />
      <meta property="og:url" content="https://saxandthecity.se/start" />
    </Helmet>
    <Views />
  </>
);

export default App;
