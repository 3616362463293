import React from 'react';
import {useHistory} from 'react-router-dom';
import {COLORS, ROUTES} from '../../constants';
import LOGO from '../../assets/logowhite.png';
import img01 from '../../assets/images/new/IMG_7011.jpeg';
import {BookButton} from '../../styled';

import {
  TreatmentContainer,
  TreatmentTitle,
  TreatmentDescription,
  LogoBookButtonWrapper,
} from '../../styled';

const PigmentVessels = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={{width: '100%'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: '100px', position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
          <img
            src={img01} alt="Image of table with Sax and the City text"
            style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
          />
        </div>
        <LogoBookButtonWrapper>
          <img src={LOGO} style={{width: '50%'}} />
          <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
        </LogoBookButtonWrapper>
      </div>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Kärlbehandling Malmö med laser</TreatmentTitle>
          <TreatmentDescription>
            <h3>Ytliga blodkärl kan göra att du får vidgade kapillärer, kärlnystan, röd näsa och diffus rodnad. Med den senaste lasertekniken behandlar vi effektivt kärl, så att de blir mindre synliga och du får uppleva en jämnare hudton som är mindre röd.</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Många lider av ytliga blodkärl i ansiktet eller på benen. Det kan göra att du har en röd näsa, röda kinder eller synliga spindelnätsliknanade ådernät på benen. Ofta är ytliga blodkärl genetiskt och ibland uppstår de efter för mycket sol eller trauma. Vi kan nu behandla ytliga blodkärl effektivt och säkert med laser.
          </TreatmentDescription>
          <TreatmentDescription>
            Vi erbjuder den absolut senaste och mest effektiva laserteknologin från Mediostar, vilket ger dig ett bättre resultat med färre behandlingar.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Behandlingen</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Inför varje behandling gör vi en noggrann koll av området vi ska behandla och tar fram en personlig behandlingsplan. Själva behandlingen är tidseffektiv och utförs genom att vi för ett munstycke över huden. En varm känsla uppstår under behandlingen. Vår laser Mediostar har ett inbyggt kylningssystem som vi ställer in efter din hud, som konsekvent kyler före, under och efter varje puls. Det ger en effektiv, men ändå bekväm, säker och trygg behandling.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Resultat</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Resultatet efter varje behandling är individuellt. Det sker en gradvis reduktion av rodnad och brustna kärl. Huden kommer också att kännas mjukare och får mer lyster efter din behandling.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Vilka typ av kärl kan vi behandla?</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            <ul>
              <li>Ytliga kärl</li>
              <li>Kärlbristningar</li>
              <li>Ådernät</li>
              <li>Solskador</li>
            </ul>
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Vad sker efter behandlingen?</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Efter behandlingen kan det uppstå en lätt rodnad som avtar efter någon till några timmar. Ibland uppstår även svullnader, som också försvinner efter något dygn. Blodkärlen kan temporärt bli mer synliga innan de minskar. Vi rekommenderar att du undviker solexponering i minst 4 veckor efter varje behandling.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Hur fungerar det?</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            Kärl uppstår genetiskt eller av yttre påfrestningar och går att behandla med laser. Lasern behandlar kärl genom att skjuta ut ett ljus som skapar värme och gör att kärlen drar ihop sig och försvinner. De blir mindre synliga och din hud blir därmed mindre röd. Vår laser Mediostar är den senaste generationens laserteknologi som levererar högre energi vid ett större område och kortare pulsvaraktighet, vilket ger dig ett bättre resultat på kortare tid och färre behandlingar.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
    </div>
  );
};

export default PigmentVessels;
