import React from 'react';
import {BrowserRouter as Router, Route, Switch, Redirect} from 'react-router-dom';
import {ROUTES} from '../constants';
import Home from './home';
import {Wrapper} from '../styled';
import Book from './bookings';
import Treatments from './treatments';
import Products from './products';
import Contact from './contact';
import Routes from '../components/routes';
import InsculptChair from './insculpt-chair';
import HairRemoval from './hair-removal';
import Acne from './acne';
import Pigment from './pigment';
import Vessels from './vessels';
import StretchMarks from './stretch-marks';
import SkinTightening from './skin-tigthening';
import Cellulites from './cellulites';

const Views = () => {
  const renderRoutes = () => (
    <Wrapper>
      <Route exact path="/"><Redirect to={ROUTES.start}/></Route>
      <Routes>
        <Route exact path={ROUTES.start} component={Home}/>
        <Route exact path={ROUTES.bookings} component={Book}/>
        <Route exact path={ROUTES.treatments} component={Treatments}/>
        <Route exact path={ROUTES.products} component={Products}/>
        <Route exact path={ROUTES.contact} component={Contact}/>
        <Route exact path={ROUTES.insculptChair} component={InsculptChair}/>
        <Route exact path={ROUTES.hairRemoval} component={HairRemoval}/>
        <Route exact path={ROUTES.acne} component={Acne}/>
        <Route exact path={ROUTES.pigment} component={Pigment}/>
        <Route exact path={ROUTES.cellulites} component={Cellulites}/>
        <Route exact path={ROUTES.vessels} component={Vessels}/>
        <Route exact path={ROUTES.skinTightening} component={SkinTightening}/>
        <Route exact path={ROUTES.stretchMarks} component={StretchMarks}/>
      </Routes>
    </Wrapper>
  );

  const renderViews = () => (
    <Router basename="/">
      <Switch>{renderRoutes()}</Switch>
    </Router>
  );

  return renderViews();
};

export default Views;
