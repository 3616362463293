import styled from 'styled-components';
import {COLORS} from '../../constants';

export const Img = styled.img`
  width: 100%;
  height: 100%;
`;

export const StyledBar = styled.section`
  z-index: 100000;
  position: fixed;
  width: 100%;
  height: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  background: ${COLORS.white};
  color: ${COLORS.black};
  box-shadow: 0px 0px 10px 1px rgba(0,0,0,0.75);
  transition: all .2s;
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
    height: ${({showMenu}) => showMenu ? '100%' : '100px'};
    top: 0;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    width: 100%;
    height: ${({showMenu}) => showMenu ? '100%' : '100px'};
    top: 0;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    width: 100%;
    height: ${({showMenu}) => showMenu ? '100%' : '100px'};
    top: 0;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 100%;
    height: ${({showMenu}) => showMenu ? '100%' : '100px'};
    top: 0;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    width: 100%;
    height: ${({showMenu}) => showMenu ? '100%' : '100px'};
    top: 0;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 100%;
    height: ${({showMenu}) => showMenu ? '100%' : '100px'};
    top: 0;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    width: 100%;
    height: 100px;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    width: 100%;
    height: 100px;
  }
`;

export const Trigger = styled.div`
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 20px;
  right: 10px;
  height: 60px;
  width: 40px;
  color: ${COLORS.black};
  display: none;
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: flex;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    display: flex;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    display: flex;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    display: flex;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    display: flex;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    display: flex;
  }
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    display: none;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    display: none;
  }
`;

export const Links = styled.section`
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: ${({showMenu}) => showMenu ? 'flex' : 'none'};
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    display: ${({showMenu}) => showMenu ? 'flex' : 'none'};
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    display: ${({showMenu}) => showMenu ? 'flex' : 'none'};
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    display: ${({showMenu}) => showMenu ? 'flex' : 'none'};
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    display: ${({showMenu}) => showMenu ? 'flex' : 'none'};
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    display: ${({showMenu}) => showMenu ? 'flex' : 'none'};
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    display: flex;
    justify-content: center;
    flex-direction: row;
  }
`;

export const Link = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
  transition: all 0.2s;
  
  &:hover {
    transition: all 0.2s;
    color: ${COLORS.gold};
  }
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    font-size: 1.2em;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #333;
    justify-content: center;
    padding: 0;
    
    &:first-child {
      border-top: 1px solid #333;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    font-size: 1.2em;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #333;
    justify-content: center;
    padding: 0;
    
    &:first-child {
      border-top: 1px solid #333;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    font-size: 1.2em;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #333;
    justify-content: center;
    padding: 0;
    
    &:first-child {
      border-top: 1px solid #333;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 1.2em;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #333;
    justify-content: center;
    padding: 0;
    
    &:first-child {
      border-top: 1px solid #333;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    font-size: 1.2em;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #333;
    justify-content: center;
    padding: 0;
    
    &:first-child {
      border-top: 1px solid #333;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    font-size: 1.2em;
    width: 100%;
    height: 100%;
    border-bottom: 1px solid #333;
    justify-content: center;
    padding: 0;
    
    &:first-child {
      border-top: 1px solid #333;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    font-size: 1.1em;
    border: none;
    display: flex;
    justify-content: center;
    width: 120px;

    &:first-child {
      border: none;
    }
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    font-size: 1.1em;
    border: none;
    display: flex;
    justify-content: center;
    width: 120px;

    &:first-child {
      border: none;
    }
  }
`;

export const Social = styled.a`
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 60px;
  height: 70px;
  color: ${({color}) => color || COLORS.brown2};
  transition: all 0.3s;

  &:hover {
    filter: brightness(1.5);
    transition: all 0.3s;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: none;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    display: none;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    display: none;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    display: none;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    display: none;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    display: none;
  }

  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    display: flex;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    display: flex;
  }
`;

export const BarTitle = styled.div`
  position: absolute;
  font-size: 1.5em;
  font-weight: 600;
  width: 200px;
  max-height: 70px;
  display: flex;
  margin-left: 20px;
  justify-content: center;
  cursor: pointer;

  &:hover {
    color: #FFF;
  }


  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    display: ${({showMenu}) => showMenu ? 'none' : 'flex'};
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    display: ${({showMenu}) => showMenu ? 'none' : 'flex'};
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    display: ${({showMenu}) => showMenu ? 'none' : 'flex'};
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    display: ${({showMenu}) => showMenu ? 'none' : 'flex'};
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    display: ${({showMenu}) => showMenu ? 'none' : 'flex'};
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    display: ${({showMenu}) => showMenu ? 'none' : 'flex'};
  }

  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    display: ${({showMenu}) => showMenu ? 'none' : 'flex'};
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    display: ${({showMenu}) => showMenu ? 'none' : 'flex'};
  }
`;

export const LinkDropdown = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  background: ${COLORS.white};
  font-size: 0.9em;
  width: 160px;
  height: 270px;
  margin-left: 140px;
  margin-top: 100px;
  border-radius: 0 0 5px 5px;
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    position: relative;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #333;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    position: relative;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #333;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    position: relative;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #333;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    position: relative;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #333;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    position: relative;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #333;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    position: relative;
    margin: 0;
    width: 100%;
    border-bottom: 1px solid #333;
  }

  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }

`;

export const LinkInDropdown = styled.div`
  box-sizing: border-box;
  padding-left: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  height: 100%;
  transition: all 0.2s;
  
  &:hover {
    transition: all 0.2s;
    color: ${COLORS.gold};
  }
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-left: 20px;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    height: 50px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-left: 20px;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-left: 20px;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-left: 20px;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-left: 20px;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 40px;
    width: 100%;
    border-bottom: 1px solid #ddd;
    padding-left: 20px;
  }

  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;
