import React from 'react';
import {useHistory} from 'react-router-dom';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  BoxBackground,
  Box,
  Slogan,
  SloganItem,
  BoxesRow,
  StartContainer,
  Container,
  Subheading,
  Heading,
  Paragraph,
  List,
  ListItem,
  StartContainerWrapper,
  StartSection,
} from './styled';
import LOGO from '../../assets/logowhite.png';
import Logo from '../../components/logo';
import {ROUTES, COLORS} from '../../constants';
import {BookButton, LogoWrapper} from '../../styled';
import bookingsbox from '../../assets/bookings-box.jpg';
import pricesbox from '../../assets/prices-box.jpg';
import logoB from '../../assets/logobwhite.png';
import hairremoval from '../../assets/hairremoval2.jpg';
import Slider from 'react-slick';
import styled from 'styled-components';
import startImage2 from '../../assets/images/new/IMG_9059.jpeg';
import startImage3 from '../../assets/slideshow/img01.jpg';
import chair from '../../assets/images/new/IMG_8947.jpeg';
import skinTighteningImg from '../../assets/images/new/IMG_9717.jpeg';
import acneImg from '../../assets/images/new/IMG_9694.jpeg';
import cellulitesImg from '../../assets/images/new/IMG_9701.jpeg';
import pigmentImg from '../../assets/images/new/IMG_9698.jpeg';
import vesselsImg from '../../assets/images/new/639D9E9F-811B-488C-BD4D-A79AE763323F.jpeg';

const settings = {
  infinite: true,
  speed: 800,
  autoplaySpeed: 5000,
  autoplay: true,
  slidesToShow: 1,
  slidesToScroll: 1,
  draggable: false,
};
export const StyledSlider = styled(Slider)`
  position: fixed;
  top: 0;
  width: 100vw;
  height: 61vh;
  
  .slick-slide {
    display: flex;
    justify-content: center;
  }
  
  img, div {
    width: 100vw;
    height: 100%;
  }
  
  img {
    objectFit: cover !important;
    opacity: 1;
    filter: brightness(80%);
    box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.75);
  }
  
  div {
    //border-bottom: 2px solid #111;
  }


  &:hover {
    transition: all 0.2s;
  }

  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 100vh;
    img, div {
      width: 100vw;
      height: 85%;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    img, div {
      width: 100vw;
      height: 100%;
    }
  }
`;

const ContentContainer = styled.div`
  z-index: 9999;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: ${COLORS.brown};
  box-sizing: border-box;
`;

const ReadMoreButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${COLORS.brown2};
  height: 30px;
  color: ${COLORS.white};
  padding: 10px;
  border-radius: 5px;
  border: none;
  cursor: pointer;

  &:hover {
    color: ${COLORS.gold};
    transition: all 0.3s;
  }
`;

const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    img {
      width: 100% !important;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) and (orientation : landscape) {
    img {
      width: 100% !important;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    img {
      width: 100% !important;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    img {
      width: 100% !important;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    img {
      width: 100% !important;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    img {
      width: 100% !important;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

const Start = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const renderSlider = () => {
    return <StyledSlider {...settings}>
      <img src={startImage2} alt=""/>
      <img src={startImage3} alt=""/>
    </StyledSlider>;
  };

  const history = useHistory();

  const renderSlogan = () => {

    return <Slogan>
      <SloganItem>
        <img src={logoB} style={{height: '50px'}} alt=""/>
        <img src={logoB} style={{height: '50px'}} alt=""/>
      </SloganItem>
      <SloganItem>
        <div style={{display: 'flex', margin: '5px', flexDirection: 'column'}}>
          <div style={{display: 'flex'}}>040-611 1 611</div>
          <div style={{display: 'flex'}}>Isbergs gata 8B</div>
          <div style={{display: 'flex'}}>21119 Malmö</div>
        </div>
        <div style={{display: 'flex', margin: '5px', flexDirection: 'column'}}>
          <div style={{display: 'flex'}}>076-856 60 17</div>
          <div style={{display: 'flex'}}>Isbergs gata 10C</div>
          <div style={{display: 'flex'}}>21119 Malmö</div>
        </div>
      </SloganItem>
    </Slogan>
  };

  const renderStartText = () => (
    <StartContainerWrapper>
      <div style={{position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
        {renderSlider()}
      </div>
      <LogoWrapper>
        <img src={LOGO} style={{width: '50%'}} />
        <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
      </LogoWrapper>
    </StartContainerWrapper>
  );

  const renderStartSection = () => (
    <StartSection height='500px'>
      <div style={{padding: window.innerWidth > '1000px' ? '0 30% 0' : '0'}}>
        <Container>
          <Heading>Våra behandlingar!</Heading>
          <Paragraph>
            Vi erbjuder ett brett utbud av frisör- och skönhetsbehandlingar som passar alla dina behov. Från barnklippningar till avancerade färgningar som balayage och ombre, tillhandahåller vi tjänster som säkerställer att du ser och känner dig fantastisk.         
          </Paragraph>
          <Paragraph>
            Våra keratinbehandlingar hjälper till att släta ut och stärka ditt hår, medan vår skäggvård håller ditt skägg snyggt och välskött. Vi erbjuder även professionell ögonbryn- och fransvård, inklusive färgning och vaxning. För speciella tillfällen som bröllop och fester, kan du lita på våra stylister för att skapa den perfekta looken.
          </Paragraph>
          <ReadMoreButton onClick={() => history.push(ROUTES.bookings)}>Läs mer här och boka här</ReadMoreButton>
        </Container>
      </div>
    </StartSection>
  );

  const renderStartSectionTwo = () => (
    <StartSection height={'600px'}>
      <ImgWrapper><img src={chair} style={{borderRadius: '5px', width: '90%'}} /></ImgWrapper>
      <div>
        <Container>
          <Heading>Upptäck INSCULPT CHAIR</Heading>
          <Paragraph>
            Nu finns det en ny och innovativ teknik som kan stärka bäckenbotten och hjälpa mot inkontinens. Vi är glada att snart kunna erbjuda denna smidiga metod för bäckenbottenträning, något som många har svårt med. Denna teknik är fördelaktig både före och efter graviditet samt under klimakteriet.
          </Paragraph>

          <Subheading>Fördelar med INSCULPT CHAIR</Subheading>
          <List>
            <ListItem>Icke-kirurgisk och smärtfri behandling</ListItem>
            <ListItem>Inga farliga kemikalier används</ListItem>
            <ListItem>Ökar blodcirkulationen</ListItem>
            <ListItem>Stärker muskulaturen</ListItem>
            <ListItem>Kräver inga terapeuter</ListItem>
            <ListItem>Du sitter fullt påklädd under hela behandlingen</ListItem>
            <ListItem>Behandlingen tar cirka 28 minuter och ger ungefär 12 000 muskelkontraktioner</ListItem>
          </List>
          <ReadMoreButton onClick={() => history.push(ROUTES.insculptChair)}>Läs mer här...</ReadMoreButton>
        </Container>
      </div>
    </StartSection>
  );
  
  const renderStartSectionThree = () => (
    <StartSection height='500px'>
      <div>
        <Container>
          <Heading>Hårborttagning</Heading>
          <Paragraph>
            Vi erbjuder professionell hårborttagning med diodlaser.
            Hårborttagning med laser är en lämplig behandling för permanent hårborttagning eller reducering av oönskad hårväxt.
          </Paragraph>
          <ReadMoreButton onClick={() => history.push(ROUTES.hairRemoval)}>Läs mer här...</ReadMoreButton>
         </Container>
      </div>
      <ImgWrapper><img src={hairremoval} style={{borderRadius: '5px', width: '90%'}} /></ImgWrapper>
    </StartSection>
  );

  const renderSectionFour = () => (
    <StartSection height='500px'>
      <ImgWrapper><img src={skinTighteningImg} style={{borderRadius: '5px', width: '90%'}} /></ImgWrapper>
      <div>
        <Container>
          <Heading>Hudåtstramning</Heading>
          <Paragraph>
            När vi åldras minskar gradvis produktionen av kollagen och elastin i huden, vilket resulterar i att huden blir slapp och rynkor börjar bildas. Laserbehandling för hudföryngring stimulerar kollagenproduktionen, vilket gör att huden stramas upp, hudtonen blir jämnare, och elasticiteten och lystern ökar.
          </Paragraph>
          <ReadMoreButton onClick={() => history.push(ROUTES.skinTightening)}>Läs mer här...</ReadMoreButton>
        </Container>
      </div>
    </StartSection>
  );

  const renderSectionFive = () => (
    <StartSection height='500px'>
      <div>
        <Container>
          <Heading>Acne</Heading>
          <Paragraph>
            Acne kan uppstå på grund av flera faktorer. En överproduktion av talg, ofta hormonellt stimulerad, kan bidra till detta. När talgkörtelns utförsgång blockeras och aknebakterier förekommer, uppstår inflammation.

            Diodlaser är effektiv mot aknebakterier i huden och hjälper till att lindra inflammationen.
          </Paragraph>
          <ReadMoreButton onClick={() => history.push(ROUTES.acne)}>Läs mer här...</ReadMoreButton>
        </Container>
      </div>
      <ImgWrapper><img src={acneImg} style={{borderRadius: '5px', width: '90%'}} /></ImgWrapper>
    </StartSection>
  );

  const renderSectionSix = () => (
    <StartSection height='500px'>
      <ImgWrapper><img src={cellulitesImg} style={{borderRadius: '5px', width: '90%'}} /></ImgWrapper>
      <div>
        <Container>
          <Heading>Celluliter</Heading>
          <Paragraph>
            Celluliter är en vanlig hudåkomma som drabbar de flesta kvinnor. De syns som gropar och ojämnheter i huden, ofta på lår, höfter och rumpa.
          </Paragraph>
          <Paragraph>
            Vår teknik arbetar effektivt mot celluliter genom att stimulera blodcirkulationen och lymfsystemet, vilket hjälper till att reducera vätskeretention och inflammation. Behandlingen tonar även huden, vilket bidrar till ett mer fast och slätt utseende. Dessutom är behandlingen idealisk för rumplyft och kroppskonturering.
          </Paragraph>
          <ReadMoreButton onClick={() => history.push(ROUTES.cellulites)}>Läs mer här...</ReadMoreButton>
        </Container>
      </div>
    </StartSection>
  );

  const renderSectionSeven = () => (
    <StartSection height='500px'>
      <div>
        <Container>
          <Heading>Pigment</Heading>
          <Paragraph>
            Fläckar i ansiktet och på kroppen uppstår lätt genetiskt eller på grund av solen. De kan även uppstå på grund av hormoner eller medicin, till exempel i samband med en graviditet. Solfläckar är ofarliga, men det finns en risk att de kan förknippas med hudförändringar som kan vara farliga. Därför är det viktigt att du får en konsultation på en läkarledd hudmottagning, som hos oss, innan din behandling.
          </Paragraph>
          <Paragraph>
            Vi erbjuder den absolut senaste och mest effektiva laserteknologin från Mediostar, vilket ger dig ett bättre resultat med färre behandlingar.
          </Paragraph>
          <ReadMoreButton onClick={() => history.push(ROUTES.pigment)}>Läs mer här...</ReadMoreButton>
        </Container>
      </div>
      <ImgWrapper><img src={pigmentImg} style={{borderRadius: '5px', width: '90%'}} /></ImgWrapper>
    </StartSection>
  );

  const renderSectionEight = () => (
    <StartSection height='500px' style={{paddingBottom: '90px'}}>
      <ImgWrapper><img src={vesselsImg} style={{borderRadius: '5px', width: '90%'}} /></ImgWrapper>
      <div>
        <Container>
          <Heading>Kärl</Heading>
          <Paragraph>
            Många lider av ytliga blodkärl i ansiktet eller på benen. Det kan göra att du har en röd näsa, röda kinder eller synliga spindelnätsliknanade ådernät på benen. Ofta är ytliga blodkärl genetiskt och ibland uppstår de efter för mycket sol eller trauma. Vi kan nu behandla ytliga blodkärl effektivt och säkert med laser.
          </Paragraph>
          <Paragraph>
            Vi erbjuder den absolut senaste och mest effektiva laserteknologin från Mediostar, vilket ger dig ett bättre resultat med färre behandlingar.
          </Paragraph>
          <ReadMoreButton onClick={() => history.push(ROUTES.vessels)}>Läs mer här...</ReadMoreButton>
        </Container>
      </div>
    </StartSection>
  );

  const renderStart = () => (
    <StartContainer>
      {renderStartText()}
      {renderStartSection()}
      <ContentContainer>
        <div style={{zIndex: '9999', padding: '0 15% 0'}}>
          {renderStartSectionTwo()}
          {renderStartSectionThree()}
          {renderSectionFour()}
          {renderSectionFive()}
          {renderSectionSix()}
          {renderSectionSeven()}
          {renderSectionEight()}
        </div>
      </ContentContainer>
    </StartContainer>
  );

  return renderStart();
};

export default Start;
