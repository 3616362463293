import styled from 'styled-components';
import {MainContainer} from '../../styled';

export const ProductsContainer = styled(MainContainer)`
  align-items: center;
  height: 100vh;

    /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
  
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {

  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
  
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
   
  }
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    padding: 0 5% 0;
    box-sizing: border-box;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    padding: 0 5% 0;
    box-sizing: border-box;
  }
`;

export const ProductsHeader = styled.div`
  display: flex;
  width: 100%;
  box-sizing: border-box;
  height: 30%;
  font-size: 1.1em;
  background: rgba(50, 50, 50, 0.8);
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 20px;
    margin-top: 40%;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    padding: 20px;
    margin-top: 40%;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    padding: 20px;
    margin-top: 40%;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    font-size: 1.2em;
    padding: 20px;
    margin-top: 20%;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    font-size: 1.2em;
    padding: 20px;
    margin-top: 20%;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    font-size: 1.2em;
    padding: 20px;
    margin-top: 20%;
  }
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    padding: 40px;
    margin-top: 5%; 
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    padding: 40px;
    margin-top: 5%;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
`;

export const ProductsBoxes = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 20%;
  width: 100%;
  background: rgba(50, 50, 50, 0.8);
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {

  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {

  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {

  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {

  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {

  }
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    height: 50%;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    height: 50%;
  }
`;

export const ProductBox = styled.section`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 200px;
  width: 200px;
  
  img {
    opacity: 0.7;
    height: 100%;
    width: 100%;
    border-radius: 10px;
  }
  
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    height: 70px;
    width: 70px;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    height: 70px;
    width: 70px;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    height: 70px;
    width: 70px;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    height: 120px;
    width: 120px;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    height: 120px;
    width: 120px;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    height: 120px;
    width: 120px;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    height: 200px;
    width: 200px;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    height: 200px;
    width: 200px;
  }
`;
