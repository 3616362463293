import styled from 'styled-components';
import {COLORS} from '../../constants';
import {MainContainer} from '../../styled';
import contactbg from '../../assets/contact-bg.jpg';

export const ContactContainer = styled(MainContainer)`
  flex-direction: row;
  box-sizing: border-box;
  align-items: center;
  background: ${COLORS.brown};
  color: ${COLORS.white};
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    flex-direction: column;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    flex-direction: column;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    flex-direction: column;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    flex-direction: column;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    flex-direction: column;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    flex-direction: column;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

export const ContactInfo = styled.section`
  display: flex;
  flex-direction: column;
  padding: 30px;
  box-sizing: border-box;
  width: 50%;
  min-height: 250px;
  text-overflow: ellipsis;
  margin-top: 10%;
    
  div {
    margin: 10px;
    width: 100%;
  }

  p {
    padding: 0;
    margin: 0;
  }
  
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
    flex-direction: column;
    font-size: 0.8em;
    margin-top: 0;
    padding-top: 20%;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    width: 100%;
    flex-direction: column;
    font-size: 0.8em;
    margin-top: 0;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    width: 100%;
    flex-direction: column;
    font-size: 0.8em;
    margin-top: 0;
    padding-top: 20%;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 100%;
    flex-direction: column;
    font-size: 1.2em;
    margin-top: 0;
    padding-top: 0;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    width: 100%;
    flex-direction: column;
    font-size: 1.2em;
    margin-top: 0;
    padding-top: 0;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 100%;
    height: 100%;
    flex-direction: column;
    font-size: 1.2em;
    padding-top: 15%;
    margin-top: 0;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    flex-direction: row;
    font-size: 1.0em;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    flex-direction: row;
    font-size: 1.0em;
  }
`;

export const ContactMap = styled.section`
  display: flex;
  padding: 10px;
  box-sizing: border-box;
  width: 50%;

  div {
    width: 50%;
    margin: 10px;
  }
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;
 
    div {
      width: 100%;
      margin: 0;
    }   

    img {
      height: 170px;
    }
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    width: 100%;
    justify-content: center;
    
    img {
      height: 170px;
    }
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    width: 100%;
    justify-content: center;
    flex-direction: column;

    div {
      width: 100%;
      margin: 0;
    }

    img {
      height: 170px;
    }
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    width: 100%;
    justify-content: center;
    
    img {
      height: 100%;
    }
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    width: 100%;
    justify-content: center;
    
    img {
      height: 100%;
    }
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    width: 100%;
    justify-content: center;
    
    img {
      height: 100%;
    }
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

export const ContactCont = styled.div`
  position: relative;
  display: flex;
  font-size: 0.9em;
  width: 100%;
  position: relative;
  box-sizing: border-box;
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-widthwrap : 480px) {
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
    font-size: 0.9em;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) {
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
    font-size: 0.9em;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    padding: 20px;
    box-sizing: border-box;
    flex-direction: column;
    font-size: 0.9em;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding: 50px;
    box-sizing: border-box;
    flex-direction: column;
    font-size: 1.1em;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    padding: 50px;
    box-sizing: border-box;
    flex-direction: column;
    font-size: 1.1em;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    padding: 50px;
    box-sizing: border-box;
    flex-direction: column;
    font-size: 1.1em;
  }
  
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
    flex-direction: column;
    width: 90%;
    padding: 3%;
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
    flex-direction: column;
    width: 90%;
  }
`;
