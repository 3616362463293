import {ROUTES} from './routes';

const COLORS = {
  brown: '#AC917B',
  brown2: '#5C4E4E',
  brownRGBA: 'rgb(172, 145, 123, 0.6)',
  brownRGBA2: 'rgb(133 88 53 / 60%);',
  gold: '#d3b574',
  white: '#FFFFFF',
  grey: '#F5F5F5',
  black: '#000000',
};
/*
const COLORS = {
  brown: '#8d735c',
  brown2: '#c8aa8e',
  brown3: '#5f3b27',
  brownRGBA: 'rgb(172, 145, 123, 0.6)',
  gold: '#d3b574',
  white: '#FFFFFF',
  grey: '#F5F5F5',
  black: '#221510',
};*/

export {
  ROUTES,
  COLORS,
};
