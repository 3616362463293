import React from 'react';
import {useHistory} from 'react-router-dom';
import {COLORS} from '../../constants';
import LOGO from '../../assets/logowhite.png';
import img01 from '../../assets/images/new/IMG_9694.jpeg';
import {BookButton} from '../../styled';
import {ROUTES} from '../../constants';

import {
  TreatmentContainer,
  TreatmentTitle,
  TreatmentDescription,
  LogoBookButtonWrapper,
} from '../../styled';

const Acne  = () => {
  const history = useHistory();
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  return (
    <div style={{width: '100%'}}>
      <div style={{display: 'flex', justifyContent: 'center'}}>
        <div style={{marginTop: '100px', position: 'relative', width: '100%', height: '50vh', background: COLORS.black}}>
          <img
            src={img01} alt="Image of table with Sax and the City text"
            style={{width: '100%', height: '100%', objectFit: 'cover', filter: 'brightness(80%)'}}
          />
        </div>
        <LogoBookButtonWrapper>
          <img src={LOGO} style={{width: '50%'}} />
          <BookButton onClick={() => history.push(ROUTES.bookings)}>Boka nu</BookButton>
        </LogoBookButtonWrapper>
      </div>
      <TreatmentContainer>
        <div>
          <TreatmentTitle>Aknebehandling Malmö med laser</TreatmentTitle>
          <TreatmentDescription>
            <h3>Hur diodlaser fungerar för akne:</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Mål</b>: Diodlaser riktar sig mot talgkörtlarna i huden, som är ansvariga för överproduktion av talg (olja), vilket ofta leder till akne.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Värme</b>: Lasern genererar värme som selektivt riktar sig mot de överaktiva talgkörtlarna, vilket minskar deras aktivitet och därmed minskar talgproduktionen.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Antibakteriell effekt</b>: Lasern har också en antibakteriell effekt som kan bidra till att döda aknebakterier (Propionibacterium acnes), vilket minskar inflammation och akneutbrott.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Hudförnyelse</b>: Värmen från lasern kan också stimulera kollagenproduktionen, vilket hjälper till att läka huden och reducera akneärr.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Fördelar med diodlaserbehandling:</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Effektiv för inflammatorisk akne</b>: Behandlingen är särskilt effektiv för patienter med inflammatorisk akne som pustler och papler.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>inskad talgproduktion</b>: Behandlingen hjälper till att kontrollera oljighet och kan bidra till att minska frekvensen av akneutbrott.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Ingen kemisk irritation</b>: Eftersom behandlingen inte involverar kemikalier är risken för allergiska reaktioner lägre.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Behandlingsprocess:</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Förberedelse</b>: Huden rengörs och en kylgel kan appliceras för att minska obehag under behandlingen.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Laserbehandling</b>: Diodlasern appliceras på huden med korta pulser som riktar sig mot aknebenägna områden.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Varaktighet</b>: En session kan ta från 10 till 30 minuter beroende på området som behandlas.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Eftervård</b>: Direkt efter behandlingen kan huden vara röd och svullen
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Biverkningar och risker:</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Tillfällig rodnad och svullnad</b>: Detta är vanligt och brukar gå över inom kort tid.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Möjlig pigmentförändring</b>: I vissa fall kan tillfälliga pigmentförändringar uppstå, särskilt på mörkare hudtyper.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Milt obehag</b>: Behandlingen kan kännas som en stickande eller brännande känsla.
          </TreatmentDescription>
          <TreatmentDescription>
            <h3>Antal behandlingar:</h3>
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Flera sessioner krävs</b>: Vanligtvis krävs en serie på 4-6 behandlingar, med några veckors mellanrum, för att uppnå bästa resultat.
          </TreatmentDescription>
          <TreatmentDescription>
            <b>Underhållsbehandlingar</b>: För att upprätthålla resultat kan underhållsbehandlingar rekommenderas efter behov.
          </TreatmentDescription>
        </div>
      </TreatmentContainer>
    </div>
  );
};

export default Acne;
