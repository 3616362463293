import styled from 'styled-components';
import {COLORS} from '../../constants';
import {MainContainer} from '../../styled';

export const BookContainer = styled(MainContainer)`
  background: ${COLORS.brown};
`;

export const Profiles = styled.section`
  display: flex;
  justify-content: center;
  background: ${COLORS.brown};
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    flex-direction: column;
    width: 100%;
    align-items: center;
    min-height: 900px;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 767px) and (orientation : landscape) {
    min-height: 900px;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    min-height: 900px;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    align-items: center;
    flex-direction: column;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    align-items: center;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    align-items: center;
  }
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {
  }
`;

export const Profile = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  height: 800px;
  color: ${COLORS.white};
  background: ${COLORS.brown};
  padding: 50px 80px 100px;
  box-sizing: border-box;
  
  /* Smartphones (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 320px) and (max-device-width : 480px) {
    padding: 10px;
    box-sizing: border-box;
    height: 500px;
    width: 100%;
  }
  
  /* Smartphones (landscape) ----------- */
  @media only screen and (min-width : 321px) and (max-width : 1024px) {
    padding: 10px;
    box-sizing: border-box;
    height: 500px;
    width: 100%;
  }
  
  /* Smartphones (portrait) ----------- */
  @media only screen and (max-width : 320px) {
    padding: 10px;
    box-sizing: border-box;
    height: 500px;
    width: 100%;
  }
  
  /* iPads (portrait and landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) {
    padding: 10px;
    box-sizing: border-box;
    height: 750px;
    width: 100%;
  }
  
  /* iPads (landscape) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : landscape) {
    padding: 10px;
    box-sizing: border-box;
    height: 750px;
    width: 100%;
  }
  
  /* iPads (portrait) ----------- */
  @media only screen and (min-device-width : 768px) and (max-device-width : 1024px) and (orientation : portrait) {
    padding: 10px;
    box-sizing: border-box;
    height: 750px;
    width: 100%;
  }
  /* Desktops and laptops ----------- */
  @media only screen  and (min-width : 1224px) {
  
  }
  
  /* Large screens ----------- */
  @media only screen  and (min-width : 1824px) {

  }
`;

export const ProfileName = styled.section`
  display: flex;
  justify-content: center;
  margin: 3px;
  width: 100%;
  font-size: 1.4em;
  font-weight: 700;
`;

export const ProfileDesc = styled.section`
  display: flex;
  margin: 3px;
  padding: 10px 20px 10px;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.9em;
  font-weight: 500;  
`;

export const ProfileItem = styled.section`
  display: flex;
  margin: 3px;
  padding: 2px 20px 2px;
  box-sizing: border-box;
  width: 100%;
  font-size: 0.9em;
  font-weight: 500;  
`;

export const ProfileContact = styled.section`
  display: flex;
  justify-content: center;
  margin: 3px;
  width: 100%;
  font-size: 0.9em;
`;

export const ContactButton = styled.section`
  cursor: ${(props => props.link && 'pointer')};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 50%;
  height: 30px;
  border-radius: 20px;
  color: #FFF;
  background: #777;
  border: 2px solid #444;
  transition: background 0.3s, color 0.3s;

  &:hover {
    color: #FFF;
    transition: background 0.3s, color 0.3s;
    background: ${(props => props.link && '#999')};
  }
`;

export const Header = styled.section`
  width: 100%;
  height: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 5em;
  color: #FFF;
`;
