import React from 'react';
import {Title} from './styled';
import LOGO from '../../assets/logo.png';

const Logo = ({top}) => {

  const renderLogoTitle = () => (
    <Title top={top}>
      <img src={LOGO} alt={''} />
    </Title>
  );

  return renderLogoTitle();
};

export default Logo;