import React from 'react';
import {ProductBox, ProductsBoxes, ProductsContainer, ProductsHeader} from './styled';
import Logo from '../../components/logo';

// Flytta till priser

const Products = () => {
  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);


  const renderLogo = () => <Logo/>;

  const renderHeader = () => (
    <ProductsHeader>
      <p>
        Vi använder endast produkter som vi älskar.
        Innan vi säljer en produkt så provar vi den på oss själva för att aldrig rekommendera något som är dåligt.
        Här nedan kan du se vilka varumärken vi säljer och använder.
      </p>
    </ProductsHeader>
  );

  const renderBody = () => (
    <ProductsBoxes>
      <ProductBox>
        <img src={'http://www.saxandthecity.se/wp-content/uploads/2015/11/loreal.png'} alt=""/>
      </ProductBox>
      <ProductBox>
        Keune
        {/*<img src={'http://www.saxandthecity.se/wp-content/uploads/2015/11/oleplex.png'} alt=""/>*/}
      </ProductBox>
      <ProductBox>
        <img src={'http://www.saxandthecity.se/wp-content/uploads/2015/11/ghd.png'} alt=""/>
      </ProductBox>
      <ProductBox>
        barber
        {/*<img src={'http://www.saxandthecity.se/wp-content/uploads/2015/11/tigi.png'} alt=""/>*/}
      </ProductBox>
    </ProductsBoxes>
  );

  const renderProducts = () => (
    <ProductsContainer>
      {renderLogo()}
      {renderHeader()}
      {renderBody()}
    </ProductsContainer>
  );

  return renderProducts();
};

export default Products;
